<template>
  <div class="overlay" :class="{ open: isOpen }">
    <div class="flex flex-col gap-y-6 items-start sm:ps-8 ps-2">
      <OverlayMenuItem
        v-for="(item, index) in menu"
        :key="index"
        v-bind="item"
      />
    </div>
    <div class="flex-1"></div>
    <div class="flex flex-col flex-none items-center gap-2 pb-4 w-full">
      <a
        class="flex items-center gap-x-2"
        :href="'tel:' + contact.phone"
        title="Call us"
      >
        <Icon icon="mdi:phone-outline"></Icon>
        <span class="text-foreground-light text-sm">{{ contact.phone }}</span>
      </a>
      <a
        class="flex items-center gap-x-2"
        :href="'mailto:' + contact.email"
        title="Email us"
      >
        <Icon icon="mdi:email-outline"></Icon>
        <span class="text-foreground-light text-sm">
          {{ contact.email }}
        </span>
      </a>
      <Button
        aria-label="Get a proposal"
        @click="openModal"
        class="bg-gradient-to-r from-primary to-primary-dark flex gap-x-2 shadow-md mt-2"
      >
        GET A PROPOSAL
        <Icon icon="mdi:arrow-right"></Icon>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";
import { useHamburgerMenu } from "~/composable/useHamburgerMenu";

const { isOpen } = useHamburgerMenu();

import { contact, menu } from "~/lib/constants";
import { useModal } from "vue-final-modal";
import ContactModal from "~/components/global/ContactModal.vue";
const openModal = async () => {
  const modal = useModal({
    component: ContactModal,
  });

  await modal.open();
};
</script>

<style scoped lang="scss">
.overlay {
  @apply fixed top-20 inset-y-0  z-10 w-screen overflow-x-hidden bg-gradient-to-b from-[#0B1E2D] to-[#236293dd] text-white  p-8 flex flex-col gap-y-4 items-start overflow-y-auto;

  transition: all 0.3s ease-in-out;
  transform: translateX(100%);
  visibility: hidden;
  &.open {
    transform: translateX(0);
    visibility: inherit;
    @apply visible lg:invisible;
  }
  height: calc(100dvh - 80px);
}
</style>
